import React, { useState } from 'react';
import Navigation from '../../Components/Navigation';
import MobileRechargeContext from '../../Context/MobileRechargeContext';
import GrowYourBussion from '../../Components/GrowYourBussion/GrowYourBussion';
import AboutPay2Mobile from '../../Components/AboutPay2Mobile';
import DownloadPay2Mobile from '../../Components/DownloadPay2Mobile';
import FooterContent from '../../Components/Footer/footer-content';
import "./Home.css";

export default function Homepage() {
	const [planDetails, setPlanDetails] = useState({});
	const [planAmount, setPlanAmount] = useState('');
	const [selectedRechargeDetails, setSelectedRechargeDetails] = useState({});

	return (
		<>
			<div className="container">
				<div className="Home-page">
					<MobileRechargeContext.Provider value={{ planDetails, setPlanDetails, planAmount, setPlanAmount, selectedRechargeDetails, setSelectedRechargeDetails }}>
						<Navigation />
					</MobileRechargeContext.Provider>
				</div>
			</div>
			<GrowYourBussion />
			{/* <AboutPay2Mobile /> */}
			<FooterContent />
			
			
			{/* */}
		</>
	);
}
